//导入布局组件
import Layout from '@/layout'

const routes = [
    {
        path: '/',
        component: Layout,
        //路由重定向到主页
        redirect: '/pay',
        children:[
            //订单支付页
            {path:'/pay',component:()=>import('@/views/pay')},
            //订单支付成功页
            {path:'/paySuccess',component:()=>import('@/views/pay/pay-success')}
        ]
    }
]

export default routes
