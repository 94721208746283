import Vue from 'vue'
import App from './App.vue'
import router from './router'

//导入mockJS开启mock服务器

// import TypeNav from '@/components/TypeNav'
// import Slide from '@/components/Slide'
//改为导入全局组件
import '@/components'


// 初始化样式(npm i normalize.css)
import 'normalize.css'

//重置样式
import './scss/reset.scss'

//导入axios
// import Axios from 'axios'

//createApp(App).use(router).mount('#app')

//全局注册导航分类组件
// Vue.component('TypeNav',TypeNav)
// Vue.component('Slide',Slide)

//全局注册事件处理中心
// Vue.prototype.$bus = new Vue()
//将Axios添加到vue实例中
// Vue.prototype.$http = Axios

//以下是旧的写法
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
